.bg-dark-nav {
	background: #1f283e;
}
.bg-light-nav {
	background: #ffffff;
}

.logo-big-wrapper {
	width: 14.6rem;
}

.logo-small-wrapper {
	width: 5.4rem;
}

.navbar-shadow {
	box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.1), 0 1px 4px 0 rgba(0, 0, 0, 0.178);
}
