.menu-shadow {
	box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.1), 0 1px 4px 0 rgba(0, 0, 0, 0.178);
}

/* SMALL SCREEN */
@media (min-width: 640px) {
	/* SOMETHING */
}

/* MEDIUM SCREEN */
@media (min-width: 768px) {
	/* SOMETHING */
}

/* LARGE SCREEN */
@media (min-width: 1024px) {
	.menu-shadow {
		box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.1), 0 1px 4px 0 rgba(0, 0, 0, 0.178);
	}
}

/* EXTRA LARGE SCREEN */
@media (min-width: 1280px) {
	/* SOMETHING */
}

/* MULTI EXTRA LARGE SCREEN */
@media (min-width: 1536px) {
	/* SOMETHING */
}

.btn-open {
	background: #6f9afa;
}
