.arrow-up {
	width: 2px;
	height: 0;
	border-left: 7px solid transparent;
	border-right: 7px solid transparent;
	border-bottom: 7px solid #dad9d9;
}

@media (min-width: 1024px) {
	.bg-dark-dropdown {
		background: #1f283e;
	}
}
