.pin-validate-shadow {
	box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.1), 0 1px 4px 0 rgba(0, 0, 0, 0.178);
}

.pin-input-shadow {
	box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.1), 0 1px 4px 0 rgba(0, 0, 0, 0.178);
}

.pin-btn-shadow {
	box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.1), 0 1px 4px 0 rgba(0, 0, 0, 0.178);
}
