.maximalContent {
	margin-top: 4rem;
	margin-bottom: 4rem;
	padding-top: 2rem;
	padding-left: 1.2rem;
	padding-right: 1.2rem;
}

.minimalContent {
	margin-top: 4rem;
	margin-bottom: 4rem;
	padding-top: 2rem;
	padding-left: 1.2rem;
	padding-right: 1.2rem;
}

/* SMALL SCREEN */
@media (min-width: 640px) {
	.maximalContent {
		margin-top: 4rem;
		padding: 2rem;
	}
	.minimalContent {
		margin-top: 4rem;
		padding: 2rem;
	}
}

/* MEDIUM SCREEN */
@media (min-width: 768px) {
	/* SOMETHING */
}

/* LARGE SCREEN */
@media (min-width: 1024px) {
	.menu-wrapper-shadow {
		box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.1), 0 1px 4px 0 rgba(0, 0, 0, 0.178);
	}
	.maximalContent {
		margin-top: 4.5rem;
		margin-bottom: 0rem;
		margin-left: 5.8rem;
		padding: 2rem;
	}
	.minimalContent {
		margin-top: 4.5rem;
		margin-bottom: 0rem;
		margin-left: 15rem;
		padding: 2rem;
	}
}

/* EXTRA LARGE SCREEN */
@media (min-width: 1280px) {
	/* SOMETHING */
}

/* MULTI EXTRA LARGE SCREEN */
@media (min-width: 1536px) {
	/* SOMETHING */
}
